import React from 'react'
import { node, oneOf } from 'prop-types'
import classNames from 'classnames'

import { useStatusContext } from '@/component/Context/StatusContext'
import styles from './FieldFeedback.module.scss'
import Type from '@/component/Primitive/Type'

const FieldFeedback = ({ children, status }) => {
  const contextStatus = useStatusContext()
  const derivedStatus = status || contextStatus

  return (
    <Type
      size="base"
      className={classNames(
        styles.FieldFeedback,
        derivedStatus && styles[derivedStatus]
      )}
      italic
    >
      {children}
    </Type>
  )
}

FieldFeedback.propTypes = {
  children: node.isRequired,
  status: oneOf(['none', 'error', 'notice', 'success', 'warning'])
}

export default FieldFeedback
