import React from 'react'
import { node } from 'prop-types'

import styles from './FieldAssistance.module.scss'
import Type from '@/component/Primitive/Type'

const FieldAssistance = ({ children }) => (
  <Type size="base" className={styles.FieldAssistance} italic>
    {children}
  </Type>
)

FieldAssistance.propTypes = {
  children: node.isRequired
}

export default FieldAssistance
